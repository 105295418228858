import React from "react";
import styled from "styled-components";
import insta from "../assets/insta.svg";

const Container = styled.footer`
  text-align: center;
  padding-top: 50px;
`;

const Footer = () => {
  return (
    <Container>
      <p>
        &copy; Liisa Tirkkonen -{" "}
        <a href="https://www.instagram.com/liisart.watercolorist/">
          liisart.watercolorist
          <img
            src={insta}
            alt="Instagram"
            style={{
              height: "20px",
              verticalAlign: "middle",
              padding: "0 6px",
            }}
          />
        </a>
      </p>
    </Container>
  );
};

export default Footer;
