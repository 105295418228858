import React, { useEffect } from "react";

import { useShoppingCart } from "use-shopping-cart";

const Success = () => {
  const { clearCart } = useShoppingCart();

  useEffect(() => clearCart(), [clearCart]);

  return (
    <main>
      <h1>
        Kiitos, kun tuet pienyrittäjää{" "}
        <span role="img" aria-label="heart emoji">
          ❤️
        </span>
      </h1>

      <p>
        Saat sähköpostiisi vahvistuksen tilauksestasi. <br />
        Jos et saa vahvistusta, ota yhteyttä{" "}
        <a href="mailto:hello.liisart@gmail.com">hello.liisart@gmail.com</a>
      </p>
    </main>
  );
};

export default Success;
