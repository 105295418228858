import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled from "styled-components";
import Success from "./pages/Success";
import Home from "./pages/Home";
import Cart from "./pages/Cart";
import Contact from "./pages/Contact";

import Header from "./components/Header";
import Footer from "./components/Footer";

import "./App.css";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  align-items: start;
  width: 100%;
  max-width: 1400px;
  min-height: 100vh;
  padding: 0;
  margin: auto;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
`;

function App() {
  return (
    <Router>
      <Container>
        <Header />
        <ContentContainer>
          <div style={{ margin: "0 40px" }}>
            <Switch>
              <Route path="/success">
                <Success />
              </Route>
              <Route path="/ostoskori">
                <Cart />
              </Route>
              <Route path="/yhteystiedot">
                <Contact />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </ContentContainer>
        <Footer />
      </Container>
    </Router>
  );
}

export default App;
