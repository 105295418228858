import React from "react";
import insta from "../assets/insta.svg";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;

  h1 {
    margin: 0;
    padding: 50px 0;
  }
`;

const Contact = () => {
  return (
    <Container>
      <h1>Yhteystiedot</h1>
      <p>
        <a href="mailto:hello.liisart@gmail.com">
          {" "}
          <span role="img" aria-label="heart emoji">
            💌
          </span>{" "}
          hello.liisart@gmail.com
        </a>
      </p>
      <p>
        {" "}
        <a href="https://www.instagram.com/liisart.watercolorist/">
          <img
            src={insta}
            alt="Instagram"
            style={{
              height: "20px",
              verticalAlign: "middle",
              padding: "0 6px",
            }}
          />
          liisart.watercolorist
        </a>
      </p>
      <p>Y-tunnus 1863344-7</p>
    </Container>
  );
};

export default Contact;
