import React, { useState } from "react";
import styled from "styled-components";

import { useShoppingCart, formatCurrencyString } from "use-shopping-cart";
import img1 from "../assets/1.jpg";

import { useHistory } from "react-router-dom";

const shippingProduct = {
  name: "Postikulut",
  description: "Postikulut Suomeen",
  sku: "shipping",
  price: 790,
  currency: "EUR",
};

const Container = styled.div`
  h1 {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 40px;
  }

  button {
    display: inline-block;
    border-radius: 50px;
    line-height: 45px;
    padding: 0 35px;
    border: none;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    font-size: 13px;
  }
`;

const Count = styled.div`
  select {
    margin-top: 20px;
    line-height: 1.25;
    font-size: 1rem;
    font-weight: 400;
    text-transform: none;
    border: 1px solid;
    border-radius: 0;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #d0d0d0;
    border-radius: 0;
    color: #222;
    height: 48px;
    padding: 0 34px 0 8px;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 10px;
  }
`;

const Summary = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 10px;
  width: 300px;
  font-size: 13px;
  padding-bottom: 40px;

  .label {
    color: rgba(0, 0, 0, 0.9);
  }
  .sum {
    font-size: 16px;
    padding: 10px 0;
    border-top: 1px solid black;
  }
`;

const CartSummary = () => {
  const [loading, setLoading] = useState(false);
  const {
    totalPrice,
    cartDetails,
    redirectToCheckout,
    setItemQuantity,
    clearCart,
    addItem,
    removeItem,
  } = useShoppingCart();

  const cartCount = cartDetails.sku_kalenteri_2024?.quantity;

  const history = useHistory();

  React.useEffect(() => {
    if (!cartCount) {
      history.push("/");
    }
  }, [history, cartCount]);

  const handleCheckout = async (event) => {
    event.preventDefault();
    setLoading(true);
    // cartDetails.sku_kalenteri_2024.quantity = cartCount;

    const { sessionId } = await fetch(
      "/.netlify/functions/create-checkout-session",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(cartDetails),
      }
    )
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    redirectToCheckout({ sessionId });
  };

  const shipping = cartDetails.shipping?.quantity ? 790 : 0;

  return (
    <Container>
      <h1>Ostoskori</h1>

      <div>
        <img
          src={img1}
          alt="Kalenteri"
          style={{ width: "200px", float: "left", paddingRight: "40px" }}
        />

        <h3>Kalenteri 2024</h3>
        <span>
          {formatCurrencyString({
            value: cartDetails.sku_kalenteri_2024?.price ?? 0,
            currency: "EUR",
          })}
        </span>

        <Count>
          <select
            defaultValue={cartCount}
            onChange={(e) => {
              setItemQuantity("sku_kalenteri_2024", Number(e.target.value));
            }}
          >
            {[...Array(cartCount < 5 ? 5 : cartCount)].map((_, i) => (
              <option key={i} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </Count>

        <span
          style={{
            cursor: "pointer",
            marginTop: "50px",
            display: "inline-block",
          }}
          onClick={() => {
            clearCart();
            history.push("/");
          }}
        >
          Poista tuote ostoskorista
        </span>
      </div>

      <div style={{ clear: "both", padding: "40px 0" }}>
        <p>
          <label>
            <input
              type="radio"
              name="delivery"
              value=""
              checked={!cartDetails.shipping}
              onChange={() => removeItem("shipping")}
            />{" "}
            Nouto (Jyväskylä)
          </label>
          {!cartDetails.shipping && (
            <>
              <br />
              <span style={{ fontSize: "13px", color: "rgb(20,20,20)" }}>
                UMC Dance Studios (Vasarakatu 9b, Jyväskylä).
                <br />
                Sovi nouto sähköpostitse{" "}
                <a href="mailto:hello.liisart@gmail.com">
                  hello.liisart@gmail.com
                </a>
              </span>
            </>
          )}
          <br />
          <br />
          <label>
            <input
              type="radio"
              name="delivery"
              value=""
              checked={cartDetails.shipping}
              onChange={() => {
                if (cartDetails.shipping) return;
                addItem(shippingProduct);
              }}
            />{" "}
            Postitus
          </label>
        </p>
        <Summary>
          <div className="label">Hinta</div>
          <div>
            {formatCurrencyString({
              value: cartDetails.sku_kalenteri_2024?.value,
              currency: "EUR",
            })}
          </div>
          <div className="label">Postikulut</div>
          <div>
            {formatCurrencyString({ value: shipping, currency: "EUR" })}
          </div>
          <div className="sum">Yhteensä </div>
          <div className="sum">
            {formatCurrencyString({
              value: totalPrice,
              currency: "EUR",
            })}
          </div>
        </Summary>
        {/* <PaymentRequest /> */}
        <button disabled={!cartCount || loading} onClick={handleCheckout}>
          {!cartCount ? "Tyhjä ostoskori" : "Siirry kassalle"}
        </button>
        <p>
          <small style={{ color: "gray" }}>
            Maksuvälittäjänä toimii Stripe.com
          </small>
        </p>
      </div>
    </Container>
  );
};

export default CartSummary;
